import http from '@/api/request'
import req from '~/api/request'

// 添加购物车
export function trolleyAdd (id) {
  return http().post('/scm/auth/shopping/cart/add', { cid: id })
}

// 购物车列表
export function trolleyList () {
  return http().post('/scm/auth/shopping/cart/list', {})
}

// 购物车删除
export function trolleyDelete (ids) {
  return http().post('/scm/auth/shopping/cart/delete', { cids: ids })
}

// 购物车数量
export function trolleyCount () {
  return http().post('/scm/auth/shopping/cart/count', {})
}

/**
 * 查询课程信息详情 -- 登录
 * @param data
 * @returns {*}
 */
export function getAuthCourseViewCar (data) {
  return http().post('/resource/auth/course/view/car', data)
}

/**
 * 统一下单  购物车
 * @param data
 * @returns {*}
 */
export function tradeOrderCartUnified (data) {
  return req().post('/scm/auth/trade/order/son/carOrder', data)
}

/**
 * 统一下单  购物车
 * @param data
 * @returns {*}
 */
export function tradeOrderCartSave (data) {
  return req().post('/scm/auth/trade/order/card/save', data)
}
export function tradeOrderCartOrderId (params) {
  return req().get('/scm/auth/trade/order/card/query/orderId', { params })
}
