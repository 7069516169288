import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import { trolleyCount } from '@/api/trolley'

export const useCartStore = defineStore('cart', () => {
  const cartNum = ref(0)

  function updateCartNum () {
    trolleyCount().then(res=>{
      console.log('res ==== ', res)
      if (typeof (res) == 'object') {
        cartNum.value = res.data
      } else {
        cartNum.value = res
      }

      console.log('cartNum = ', cartNum.value)
    })
  }

  return {
    cartNum,
    updateCartNum,
  }
})
if (import.meta.hot) { import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot)) }
